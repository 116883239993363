import styles from '../../styles/Form.module.css';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


const FormPhone = ({ label, value, setValue, field, dataContent, errorMessage }) => {
	const onChangeValue = (value) => {
		field.onChange(value)
		setValue(value)
	}
	return (
		<div className={styles.formInputContainer}>
			<div className={styles.formInput} data-content={dataContent}>
				{
					label && <p className={styles.formInputLabel}>{label}</p>
				}

				<ReactPhoneInput
					{...field}
					country={"ng"}
					onChange={onChangeValue}
					value={value}
					placeholder=""
				/>
			</div>
			{
				errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>
			}
		</div>
	)
}

export default FormPhone;
